import { getBenefitsMenuGroup } from "./benefitsMenuGroup";
import { getExpensesMenuGroup } from "./expensesMenuGroup";
import { getPeopleMenuGroup } from "./peopleMenuGroup";
import {
  MenuGroupsProps,
  MenuProps,
  SubMenuProps,
  PeopleContractModules,
  IExpensesContracts,
} from "./types";
import {
  IPermissionsFlags,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { UsuarioFolhaCerta } from "../../api";

export const getMenuGroups = (
  unleashToggles: IPermissionsFlags["flags"],
  permissions: string[],
  isAdmin: boolean,
  hasSettings: boolean,
  benefitsIsLoading: boolean,
  folhaCertaUser: UsuarioFolhaCerta | null,
  companyId: string,
  peopleContractModules?: PeopleContractModules,
  expensesContractsData?: IExpensesContracts,
  isCorporateCardUser?: boolean,
  isExpenseLifecycleEnabled?: boolean,
  isCorporateCardEnabled?: boolean
): MenuGroupsProps[] => {
  const expensesMenuGroup = getExpensesMenuGroup(
    isAdmin,
    hasSettings,
    permissions,
    companyId,
    expensesContractsData,
    unleashToggles,
    isExpenseLifecycleEnabled,
    isCorporateCardEnabled
  );
  const peopleMenuGroup = getPeopleMenuGroup(
    folhaCertaUser,
    peopleContractModules
  );
  const benefitsMenuGroup = getBenefitsMenuGroup(
    benefitsIsLoading,
    unleashToggles
  );

  let menuGroups: MenuGroupsProps[] = [
    {
      menus: [
        {
          name: "home",
          route: "/home",
          icon: "IconSmartHome",
        },
        {
          name: "employees",
          route: "/employees",
          icon: "IconUsers",
          permissions: [
            "core_manage_employees_list",
            "core_create_employee",
            "core_deactivate_employee",
            "core_view_employees_profile",
            "core_edit_employees_profile",
            "core_update_employees_fields",
          ],
        },
        {
          name: "cards",
          route: "/cards",
          icon: "IconCreditCard",
          permissions: [
            "operations_view_cards",
            "operations_view_card_orders",
            "operations_create_card_order",
            "operations_create_batch_card_order",
          ],
        },
        {
          name: "myFlash.title",
          icon: "IconWallet",
          route: "/myflash",
          subMenus: [
            {
              name: "myFlash.statement",
              route: "/corporateCard/statement",
            },
            {
              name: "myFlash.cards",
              route: "/corporateCard/cards",
            },
            ...(isCorporateCardUser
              ? [
                  {
                    name: "myFlash.wallet",
                    route: "/corporateCard/wallet",
                  },
                ]
              : []),
          ],
        },
        {
          icon: "IconBuildingStore",
          name: "marketplace",
          route: "/marketplace",
          permissions: ["core_contract_products"],
        },
        {
          icon: "IconBuildingStore",
          name: "appStore",
          route: "/acquisition/app-store",
          permissions: ["core_contract_products"],
          unleashToggleName: "FLASH_OS_APP_STORE",
        },
        {
          icon: "IconFolder",
          name: "documentManagement.title",
          route: "/document-management",
          permissions: ["core_manage_documents"],
        },
        {
          icon: "IconPresentation",
          name: "reports.title",
          route: "/reports",
          unleashToggleName: "FLASH_OS_ENABLE_REPORTS",
          permissions: [
            "benefits_billets_reportview",
            "core_manage_employees_list",
          ],
        },
      ],
    },
    benefitsMenuGroup,
    expensesMenuGroup,
    peopleMenuGroup,
  ];

  function getAllowedMenuItems<
    T extends {
      permissions?: MenuProps["permissions"];
      unleashToggleName?: MenuProps["unleashToggleName"];
    }
  >(menuItems: T[]) {
    return (menuItems || []).filter((menuItem) => {
      const isUnleashToggleActive =
        !menuItem?.unleashToggleName ||
        unleashToggles.some((flag) => flag.name === menuItem.unleashToggleName);
      if (!isUnleashToggleActive) return false;

      return (
        !menuItem.permissions ||
        permissions?.includes("*") ||
        menuItem.permissions?.some((p) => permissions?.includes(p))
      );
    });
  }

  menuGroups.forEach((_menuGroup) => {
    _menuGroup.menus = getAllowedMenuItems<MenuProps>(_menuGroup.menus);
    _menuGroup.menus.forEach((menu) => {
      if (menu.subMenus) {
        menu.subMenus = getAllowedMenuItems<SubMenuProps>(menu.subMenus);
      }
    });
    _menuGroup.menus = _menuGroup.menus.filter(
      (menu) => !menu.subMenus || menu.subMenus?.length > 0
    );
  });

  return menuGroups.filter((_group) => Boolean(_group.menus?.length));
};
