import {
  Axios,
  getAccessTokenPayloadSync as getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import useSWR from "swr";

export function useCheckCorporateCardUser(): ModuleData {
  const { selectedCompany } = useSelectedCompany();
  const cacheKey = `expenses-corporate-card-module-${selectedCompany.id}`;

  async function getHasCorporateCard(): Promise<ModuleData> {
    const { employeeId } = getAccessToken();

    try {
      const response = await Axios({
        service: "corporateCardBff",
        method: "get",
        url: `/user.checkCorporateCard,company.contract.hasCorporateCard?batch=1&input=${JSON.stringify(
          {
            0: {
              json: {
                employeeId,
              },
            },
            1: {
              json: null,
            },
          }
        )}`,
        axiosOptions: {
          timeout: 30000, // 30 seconds
          headers: {
            "Company-Id": selectedCompany.id,
          },
        },
      });

      return {
        isCorporateCardUser:
          response.data[0].result?.data?.json?.corporateCardEnabled ?? false,
        hasCorporateCard: response.data[1].result?.data?.json ?? false,
      };
    } catch (error) {
      return {
        isCorporateCardUser: false,
        hasCorporateCard: false,
      };
    }
  }

  const { data } = useSWR<ModuleData>(
    selectedCompany.id ? cacheKey : null,
    getHasCorporateCard,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      fallbackData: {
        isCorporateCardUser: false,
        hasCorporateCard: false,
      },
    }
  );

  return data;
}

type ModuleData = {
  isCorporateCardUser: boolean;
  hasCorporateCard: boolean;
};
