import { useTranslation } from "react-i18next";
import { SubMenuProps } from "../../types";
import {
  StyledListItem,
  Title,
  ItemContainer,
  NewTag,
  NewTagText,
} from "./styles";

interface SubMenuItemProps extends SubMenuProps {
  sideBarOpen: boolean;
  isNew?: boolean;
}

const SubMenuItem = ({
  name,
  route,
  sideBarOpen,
  onClick,
  isNew,
}: SubMenuItemProps) => {
  const [translations] = useTranslation("translations");

  return (
    <StyledListItem onClick={onClick}>
      <ItemContainer to={route} end={true}>
        <Title variant="body4" $sideBarOpen={sideBarOpen}>
          {name}
        </Title>
        {isNew && (
          <NewTag>
            <NewTagText variant="caption" tag="p">
              {translations("menuItem.newItem")}
            </NewTagText>
          </NewTag>
        )}
      </ItemContainer>
    </StyledListItem>
  );
};

export default SubMenuItem;
