import styled from "styled-components";
import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacings.xs3} 0`};
`;

const ItemContainer = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: ${({ $active }) => ($active ? "flex-start" : "center")};
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs2} 0`};
  text-decoration: none !important;
  cursor: ${({ $active }) => ($active ? "pointer" : "default")};
  user-select: none;
  pointer-events: ${({ $active }) => ($active ? "auto" : "none")};
`;

const IconContainer = styled.div<{ $active: boolean; open: boolean }>`
  display: flex;
  margin-left: 6px;
  ${({ $active }) => !$active && "flex: 0; display: none;"}
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease;
`;

const StyledIcon = styled(Icons)<{ primary: boolean }>`
  color: ${({ theme }) => theme.colors.neutral[70]};
  fill: ${({ theme }) => theme.colors.neutral[70]};
`;

const Title = styled(Typography)<{ $active: boolean }>`
  display: block;
  display: -webkit-box;
  transition: opacity 0.3s ease-in;
  overflow: hidden;
  flex: 1;
  margin: 0px 1px;

  ${({ $active }) => !$active && "text-align: center"};

  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral[60]};
    line-height: 1.2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const MenusContainer = styled.div<{ showMenus: boolean }>`
  opacity: ${({ showMenus }) => (showMenus ? 1 : 0)};
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacings.xs4};
`;

export {
  ItemContainer,
  IconContainer,
  StyledIcon,
  Title,
  Container,
  MenusContainer,
};
