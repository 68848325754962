import { useMemo, useState } from "react";
import {
  StyledCompanyItem,
  StyledCompanyItemDescription,
  StyledCompanyItemTitle,
  StyledCompanyTextContainer,
  StyledContentContainer,
  StyledEmptyStateContainer,
  StyledEmptyStateDescription,
  StyledEmptyStateIconContainer,
  StyledEmptyStateTitle,
  StyledFooterContainer,
  StyledList,
  StyledModalDescription,
  StyledModalHeaderContainer,
  StyledModalTitle,
  StyledSearchInputContainer,
  StyledSearchInputIcon,
  StyledTextField,
} from "./styles";
import { usePermissions } from "@flash-tecnologia/hros-web-utility";
import {
  Button,
  Icons,
  LinkButton,
  PillButton,
  Radio,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Company } from "@flash-tecnologia/hros-web-utility/dist/custom-hooks/types";
import { Modal } from "@flash-tecnologia/flamingo-ui-modal";

type ModalSelectCompanyProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (company: Company) => void;
};

export const ModalSelectCompany = ({
  open,
  onClose,
  onSubmit,
}: ModalSelectCompanyProps) => {
  const permissions = usePermissions();
  const [search, setSearch] = useState("");
  const [selectedModalCompany, setSelectedModalCompany] = useState("");

  const companies = useMemo(() => {
    const [employeeCurrentCompany, ...companies] = permissions.companies;
    const companiesWithPermissions = companies.filter(
      (company) => company.permissions.length || permissions.isAdmin
    );

    return [employeeCurrentCompany, ...companiesWithPermissions];
  }, [permissions]);

  const filteredCompanies = useMemo(() => {
    return companies
      .filter((company) => {
        return (
          company.name.toLowerCase().includes(search.toLowerCase()) ||
          company.registrationNumber.includes(search)
        );
      })
      .map((company) => {
        const isActive = company.id === selectedModalCompany;
        return (
          <StyledCompanyItem
            onClick={() => setSelectedModalCompany(company.id)}
            active={isActive}
          >
            <StyledCompanyTextContainer>
              <StyledCompanyItemTitle
                active={isActive}
                variant="body4"
                weight={700}
              >
                {company.name}
              </StyledCompanyItemTitle>
              <StyledCompanyItemDescription active={isActive} variant="caption">
                CNPJ {company.registrationNumber}
              </StyledCompanyItemDescription>
            </StyledCompanyTextContainer>
            <Radio checked={isActive} />
          </StyledCompanyItem>
        );
      });
  }, [search, companies, selectedModalCompany]);

  const isSearching = useMemo(() => search.length > 0, [search]);
  const hasResults = useMemo(
    () => filteredCompanies.length > 0,
    [filteredCompanies]
  );

  return (
    <Modal.Root
      modal
      size="small"
      open={open}
      onOpenChange={(open) => (!open ? onClose() : null)}
    >
      <Modal.Content.Root>
        <StyledModalHeaderContainer>
          <StyledModalTitle variant="headline9" weight={700}>
            Escolha a empresa para visualizar seu perfil
          </StyledModalTitle>
          <StyledModalDescription variant="body4" weight={400}>
            Ao acessar seu perfil individual, você sairá da visão unificada e
            será direcionado para a visão específica de uma empresa. Selecione
            abaixo a empresa que deseja visualizar.
          </StyledModalDescription>
        </StyledModalHeaderContainer>
        <StyledContentContainer>
          <StyledSearchInputContainer>
            <StyledSearchInputIcon size={18} name="IconSearch" />
            <StyledTextField
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar empresa"
            />
          </StyledSearchInputContainer>
          {isSearching && !hasResults ? (
            <EmptyState />
          ) : (
            <StyledList>{filteredCompanies}</StyledList>
          )}
        </StyledContentContainer>
        <Modal.Content.Footer>
          <StyledFooterContainer>
            <LinkButton onClick={() => onClose()} variant="neutral">
              Cancelar
            </LinkButton>
            <Button
              disabled={!selectedModalCompany}
              onClick={() =>
                onSubmit(companies.find((c) => c.id === selectedModalCompany))
              }
              variant="primary"
              variantType="default"
            >
              Ver meu perfil <Icons name="IconArrowRight" />
            </Button>
          </StyledFooterContainer>
        </Modal.Content.Footer>
      </Modal.Content.Root>
    </Modal.Root>
  );
};

const EmptyState = () => {
  return (
    <StyledEmptyStateContainer>
      <StyledEmptyStateIconContainer>
        <PillButton
          disabled
          icon="IconListSearch"
          size="extra-small"
          variant="default"
        />
      </StyledEmptyStateIconContainer>
      <StyledEmptyStateTitle variant="caption" weight={700}>
        Não encontramos resultados para a sua busca
      </StyledEmptyStateTitle>
      <StyledEmptyStateDescription variant="caption" weight={400}>
        Verifique se o texto digitado corresponde ao nome de alguma empresa.
      </StyledEmptyStateDescription>
    </StyledEmptyStateContainer>
  );
};
