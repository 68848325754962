import { setInLS } from "@flash-tecnologia/hros-web-utility";

export interface IAccessToken {
  employeeId: string;
}

export const setCompanySelectedAndDispatchEvent = (company: {
  id: string;
  name: string;
  registrationNumber: string;
}) => {
  const keyLS: string = "selectedCompany";
  setInLS({ key: keyLS, value: company });
  dispatchEvent(
    new CustomEvent("header_company_selected", {
      detail: {
        id: company.id,
        name: company.name,
        registrationNumber: company.registrationNumber,
      },
    })
  );
};
