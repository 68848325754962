import { useMemo } from "react";
import {
  usePermissions,
  useSelectedCompany,
  useLegacyPermissions,
  useContractModules,
  legacyGetFromLS,
} from "@flash-tecnologia/hros-web-utility";
import { useUnleashToggles } from "../utils/useUnleashToggles";
import { ErrorBoundary } from "../utils/ErrorBoundary";
import { checkUnleashToggle } from "../utils/checkUnleashPermission";
import { useFolhaCertaUser } from "./useFolhaCertaUser";
import { useExpensesContracts } from "./useExpensesContracts";
import { useCheckCorporateCardUser } from "./useCheckCorporateCardUser";
import { useCheckActiveModule as useCheckExpenseModule } from "@flash-tecnologia/expense-web-activation-utils";

import { getMenuGroups } from "../components/Menu/menuGroups";
import { EExpenseContractType } from "../components/MenuV2/types";
import { useSession } from "./use-session";

export const useMountedMenuGroup = () => {
  const clientData = legacyGetFromLS("clientData");
  const { selectedCompany } = useSelectedCompany();
  const { companies, isAdmin } = usePermissions();
  const unleashToggles = useUnleashToggles({ companyId: selectedCompany.id });
  const { isLoading, permissions: legacyPermissions } = useLegacyPermissions();
  const peopleContractModules = useContractModules();
  const { expensesContractsData } = useExpensesContracts();
  const { isCorporateCardUser } = useCheckCorporateCardUser();
  const isExpenseLifecycleEnabled = useCheckExpenseModule("lifecycle");
  const { userId } = useSession();

  const hasTimeAndAttendance = peopleContractModules?.modules?.includes?.(
    "time-and-attendance"
  );
  const { folhaCertaUser } = useFolhaCertaUser({
    enableRequest: hasTimeAndAttendance,
    selectedCompanyId: selectedCompany?.id,
  });

  const companyPermissions = useMemo(() => {
    if (companies && selectedCompany?.id) {
      const selectedCompanyPermissions = companies.find(
        (company) => company.id === selectedCompany.id
      );
      return selectedCompanyPermissions;
    }
    return null;
  }, [companies, selectedCompany]);

  const mountedMenuGroup = useMemo(() => {
    const hasSettings =
      companyPermissions?.permissions.includes(
        "core_manage_company_settings"
      ) || companyPermissions?.permissions.includes("*");

    const hasBenefits = Boolean(legacyPermissions?.benefits?.pageView?.length); //checkSectionPermission("flash_benefits") //
    const benefitsSectionToggle = checkUnleashToggle(
      "FLASH_OS_BLOCKED_TO_BENEFITS_SECTION",
      unleashToggles
    );
    const myFlashSectionToggle = checkUnleashToggle(
      "FLASH_OS_BLOCKED_TO_MY_FLASH",
      unleashToggles
    );

    let menuGroups = getMenuGroups(
      unleashToggles,
      companyPermissions?.permissions || [],
      isAdmin,
      hasSettings,
      isLoading,
      folhaCertaUser,
      selectedCompany?.id ?? "",
      peopleContractModules || null,
      expensesContractsData,
      isCorporateCardUser,
      isExpenseLifecycleEnabled
    );

    if (!hasBenefits || benefitsSectionToggle) {
      menuGroups = menuGroups.filter((group) => group.id !== "flash_benefits");

      if (companyPermissions?.permissions?.length) {
        const error = new Error(
          `The administrator does not have access to the benefits screen`
        );

        ErrorBoundary.captureException(error, "error", {
          employeeId: userId,
          companyId: selectedCompany?.id,
          hasBenefits: hasBenefits,
          unleashToggle: benefitsSectionToggle,
          isAdmin: isAdmin,
          permissions: JSON.stringify(companyPermissions?.permissions || []),
          legacyPermissions: JSON.stringify(legacyPermissions),
        });
      }
    }

    if (myFlashSectionToggle) {
      const index = menuGroups[0].menus.findIndex(
        (menuItem) => menuItem.name === "myFlash.title"
      );
      menuGroups[0].menus.splice(index, 1);
    }

    const expenseAdminAnalyticsPermissions = [
      "*",
      "expense_view_reports_approval",
      "expense_manage_corporate_cards",
      "expense_manage_corporate_card_balances",
      "expense_vendor_view",
      "expense_view_financial_reports",
      "expense_payables_account_view",
      "expense_view_approvals_advances",
      "expense_view_customizations",
    ];
    if (
      companyPermissions?.permissions?.some((permission) =>
        expenseAdminAnalyticsPermissions?.includes(permission)
      ) &&
      (expensesContractsData?.features?.includes("BASIC_ANALYTICS") ||
        expensesContractsData?.contracts?.some(
          (contract) =>
            contract === EExpenseContractType.FLASH_APP_CONTRACT_CORPORATE_CARD
        ))
    ) {
      menuGroups
        .find((menu) => menu.id === "flash_expenses")
        ?.menus?.push({
          name: "expenses.admin.analytics",
          icon: "IconBuildingStore",
        });
    }

    const peopleAdminOrgchartPermission = ["*", "people_orgchart_update"];
    const hasPeopleOrgchart = menuGroups
      .find((menu) => menu.id === "flash_people")
      ?.menus?.find((submenu) => submenu.name === "people.orgchart");

    if (
      hasPeopleOrgchart &&
      companyPermissions?.permissions?.some((permission) =>
        peopleAdminOrgchartPermission.includes(permission)
      )
    ) {
      menuGroups
        .find((menu) => menu.id === "flash_people")
        .menus.push({
          name: "people.admin.orgchart",
          icon: "IconBuildingStore",
        });
    }

    const hasPeoplePermissionByFolhaCerta =
      folhaCertaUser?.IsAdmin || folhaCertaUser?.IsGestor;

    if (hasPeoplePermissionByFolhaCerta) {
      const timeAndAttendanceMenu = {
        ...menuGroups
          ?.find((menu) => menu.id === "flash_people")
          ?.menus?.find(
            (menu) => menu.name === "people.timeAndAttendance.timeAndAttendance"
          ),
      };

      menuGroups
        .find((menu) => menu.id === "flash_people")
        .menus.push(timeAndAttendanceMenu);
    }

    return menuGroups;
  }, [
    unleashToggles,
    companyPermissions,
    isAdmin,
    isLoading,
    folhaCertaUser,
    selectedCompany,
    peopleContractModules,
    expensesContractsData,
    isCorporateCardUser,
    isExpenseLifecycleEnabled,
    clientData,
    legacyPermissions,
  ]);

  return {
    menuGroups: mountedMenuGroup,
  };
};
