import { MenuGroupsProps, MenuProps } from "../../types";
import { MenuV2Arguments } from "../../types/menu-v2-arguments";
import { setUpAdminMenuForExpenseLifecycle } from "../setup/set-up-expense-lifecycle-menus";
import { getAdminMenu } from "./adminMenu";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

const menuV1ItemIsEnabled = (menuItem: MenuGroupsProps, menuName: string) => {
  return !!menuItem?.menus.find((menu) => menu.name === menuName);
};
const menuV1SubItemIsEnabled = (
  menuItem: MenuGroupsProps,
  menuName: string,
  submenuName: string
) => {
  return !!menuItem?.menus
    .find((menu) => menu.name === menuName)
    ?.subMenus?.find((submenu) => submenu.name === submenuName);
};

export const mapAdminMenuFromV1 = ({
  args,
  menuV1Groups,
  hasSettings,
}: {
  args: MenuV2Arguments;
  menuV1Groups: MenuGroupsProps[];
  hasSettings: boolean;
}): {
  menus: (MenuGroupsProps | MenuProps)[];
  newProducts: { name: string; to: string }[];
} => {
  const groupArray: (MenuGroupsProps | MenuProps)[] = [];
  const adminMenu = getAdminMenu();
  const adminMenuButtons = adminMenu.menuItems;
  const adminNewProducts = adminMenu.newProducts;
  adminMenuButtons.home.active = true;
  adminMenuButtons.settings.active = hasSettings;
  adminMenuButtons.marketplace.active = menuV1ItemIsEnabled(
    menuV1Groups[0],
    "marketplace"
  );
  adminMenuButtons.appStore.active = menuV1ItemIsEnabled(
    menuV1Groups[0],
    "appStore"
  );
  adminMenuButtons.documentManagement.active = menuV1ItemIsEnabled(
    menuV1Groups[0],
    "documentManagement.title"
  );

  adminMenuButtons.reports.active = menuV1ItemIsEnabled(
    menuV1Groups[0],
    "reports.title"
  );

  adminMenuButtons.users.active = menuV1ItemIsEnabled(
    menuV1Groups[0],
    "employees"
  );
  adminMenuButtons.cards.active = menuV1ItemIsEnabled(menuV1Groups[0], "cards");
  adminMenuButtons.benefits.active = false;
  menuV1Groups.forEach((menuItem) => {
    switch (menuItem.id) {
      case "flash_benefits":
        adminMenuButtons.benefits.active = true;

        adminMenuButtons.benefits.menus.companyBenefits.subMenus.overView.active =
          menuV1ItemIsEnabled(menuItem, "benefits.yourBenefits");

        const benefitManagement = menuItem.menus.find(
          (menu) => menu.name === "benefits.yourBenefits"
        );

        if (benefitManagement) {
          adminMenuButtons.benefits.menus.companyBenefits.subMenus.overView.route =
            benefitManagement.route;
        }

        adminMenuButtons.benefits.menus.companyBenefits.subMenus.setup.active =
          menuV1ItemIsEnabled(menuItem, "benefits.simpleAssignment");

        adminMenuButtons.benefits.menus.companyBenefits.subMenus.orders.active =
          menuV1ItemIsEnabled(menuItem, "benefits.employees");

        adminMenuButtons.benefits.menus.companyBenefits.subMenus.placedOrders.active =
          menuV1ItemIsEnabled(menuItem, "benefits.placedOrders");

        adminMenuButtons.benefits.menus.companyBenefits.subMenus.deposits.active =
          menuV1ItemIsEnabled(menuItem, "benefits.deposits");

        adminMenuButtons.benefits.menus.points.subMenus.customBenefits.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "benefits.points.title",
            "benefits.points.customBenefits"
          );

        adminMenuButtons.benefits.menus.points.subMenus.policies.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "benefits.points.title",
            "benefits.points.policies"
          );

        adminMenuButtons.benefits.menus.points.subMenus.policiesAssignment.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "benefits.points.title",
            "benefits.points.policiesAssignment"
          );

        adminMenuButtons.benefits.menus.finance.subMenus.billets.active =
          menuV1SubItemIsEnabled(menuItem, "finance.title", "finance.payments");

        adminMenuButtons.benefits.menus.finance.subMenus.cash.active =
          menuV1SubItemIsEnabled(menuItem, "finance.title", "finance.balance");

        adminMenuButtons.benefits.menus.payrollLoan.active =
          menuV1ItemIsEnabled(menuItem, "benefits.payrollLoan");

        adminMenuButtons.benefits.menus.reports.active = menuV1ItemIsEnabled(
          menuItem,
          "reports"
        );

      case "flash_expenses":
        adminMenuButtons.expense.active = true;

        adminMenuButtons.expense.menus.dashboard.active = menuV1ItemIsEnabled(
          menuItem,
          "expenses.dashboard"
        );

        adminMenuButtons.expense.menus.approvals.active = menuV1ItemIsEnabled(
          menuItem,
          "expenses.approvals"
        );

        adminMenuButtons.expense.menus.accounting.subMenus.advances.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.accounting.title",
            "expenses.accounting.advances"
          );

        adminMenuButtons.expense.menus.accounting.subMenus.accountability.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.accounting.title",
            "expenses.accounting.accountability"
          );

        adminMenuButtons.expense.menus.accounting.subMenus.companyPayables.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.accounting.title",
            "expenses.accounting.payables"
          );

        adminMenuButtons.expense.menus.suppliers.active = menuV1ItemIsEnabled(
          menuItem,
          "expenses.vendors"
        );

        adminMenuButtons.expense.menus.customizations.active =
          menuV1ItemIsEnabled(menuItem, "expenses.customizations");

        adminMenuButtons.expense.menus.corporateCard.active =
          menuV1ItemIsEnabled(menuItem, "expenses.corporateCard.title");

        adminMenuButtons.expense.menus.corporateCard.subMenus.balance.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.corporateCard.title",
            "expenses.corporateCard.home"
          );

        adminMenuButtons.expense.menus.externalCards.active =
          menuV1ItemIsEnabled(menuItem, "admin.expense.externalCards");

        const menu = menuItem.menus.find(
          (menu) => menu.name === "expenses.corporateCard.title"
        );

        const corporateCardBalanceMenu = menu?.subMenus?.find(
          (subMenu) => subMenu.name === "expenses.corporateCard.management"
        );
        adminMenuButtons.expense.menus.corporateCard.subMenus.balance.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.corporateCard.title",
            "expenses.corporateCard.management"
          );
        if (corporateCardBalanceMenu) {
          adminMenuButtons.expense.menus.corporateCard.subMenus.balance.route =
            corporateCardBalanceMenu.route;
        }

        const corporateCardManagementMenu = menu?.subMenus?.find(
          (subMenu) => subMenu.name === "expenses.corporateCard.home"
        );
        adminMenuButtons.expense.menus.corporateCard.subMenus.cards.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.corporateCard.title",
            "expenses.corporateCard.home"
          );

        if (corporateCardManagementMenu) {
          adminMenuButtons.expense.menus.corporateCard.subMenus.cards.route =
            corporateCardManagementMenu.route;
        }
        const corporateCardAccounts = menu?.subMenus?.find(
          (subMenu) => subMenu.name === "expenses.corporateCard.accounts"
        );
        adminMenuButtons.expense.menus.corporateCard.subMenus.accounts.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.corporateCard.title",
            "expenses.corporateCard.accounts"
          );

        if (corporateCardAccounts) {
          adminMenuButtons.expense.menus.corporateCard.subMenus.accounts.route =
            corporateCardAccounts.route;
        }

        const corporateCardStatement = menu?.subMenus?.find(
          (subMenu) => subMenu.name === "expenses.corporateCard.statement"
        );
        adminMenuButtons.expense.menus.corporateCard.subMenus.statement.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "expenses.corporateCard.title",
            "expenses.corporateCard.statement"
          );

        if (corporateCardStatement) {
          adminMenuButtons.expense.menus.corporateCard.subMenus.statement.route =
            corporateCardStatement.route;
        }

        adminMenuButtons.expense.menus.analytics.active = menuV1ItemIsEnabled(
          menuItem,
          "expenses.admin.analytics"
        );

      case "flash_people":
        adminMenuButtons.people.active = true;

        adminMenuButtons.people.menus.employmentLifecycle.subMenus.hiring.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.manageFlows.flows",
            "people.manageFlows.hiring"
          );
        adminMenuButtons.people.menus.employmentLifecycle.subMenus.termination.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.manageFlows.flows",
            "people.manageFlows.resignation"
          );
        adminMenuButtons.people.menus.employmentLifecycle.subMenus.recognition.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.manageFlows.flows",
            "people.manageFlows.recognition"
          );
        adminMenuButtons.people.menus.timeAndAttendance.subMenus.activities.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.activities"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.approvals.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.approvals"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.timeSheets.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.timeSheets"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.integrations.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.integrations"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.absences.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.events"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.manageWorkshifts.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.manageWorkshifts"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.vacations.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.vacations"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.timeBank.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.bankHours"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.dataExport.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.exportData"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.workScheduleRules.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.preferences"
          );

        adminMenuButtons.people.menus.timeAndAttendance.subMenus.imports.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.imports"
          );

        adminMenuButtons.people.menus.lms.active = menuV1SubItemIsEnabled(
          menuItem,
          "people.lms.courses",
          "people.lms.manageCourses"
        );

        adminMenuButtons.people.menus.engagement.subMenus.communications.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.engagement.engagement",
            "people.engagement.manageCommunications"
          );

        adminMenuButtons.people.menus.engagement.subMenus.surveys.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.engagement.engagement",
            "people.engagement.manageSurveys"
          );

        adminMenuButtons.people.menus.performance.subMenus.evaluations.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.manageEvaluations"
          );

        adminMenuButtons.people.menus.performance.subMenus.feedbacks.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.manageFeedbacks"
          );

        adminMenuButtons.people.menus.performance.subMenus.idp.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.manageIdp"
          );

        adminMenuButtons.people.menus.performance.subMenus.cycles.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.manageCycles"
          );

        adminMenuButtons.people.menus.performance.subMenus.criterials.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.manageCriterials"
          );

        adminMenuButtons.people.menus.orgchart.active = menuV1ItemIsEnabled(
          menuItem,
          "people.admin.orgchart"
        );

        adminMenuButtons.people.menus.insights.active = menuV1ItemIsEnabled(
          menuItem,
          "people.analytics"
        );

        adminMenuButtons.people.menus.recruitment.subMenus.jobOpenings.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.recruitment.recruitment",
            "people.recruitment.jobOpenings"
          );
    }
  });

  if (args.isExpenseLifecycleEnabled) {
    setUpAdminMenuForExpenseLifecycle(
      adminMenuButtons,
      args.companyPermissions
    );
  }

  //Filter menus with active items/subItems
  Object.values(adminMenuButtons).forEach((group) => {
    if ("menus" in group) {
      let activeMenus: MenuProps[] = [];
      Object.values(group.menus).forEach((menu) => {
        if ("subMenus" in menu) {
          const activeSubMenus = Object.values(menu.subMenus).filter(
            (submenu: { active: boolean }) => submenu.active
          );
          if (activeSubMenus.length) {
            activeMenus.push({
              ...menu,
              icon: menu.icon as IconTypes,
              subMenus: activeSubMenus,
            });
          }
          return;
        }
        if (menu.active) {
          activeMenus.push(menu as MenuProps);
        }
      });
      if (activeMenus.length && group.active) {
        groupArray.push({ ...group, menus: activeMenus });
      }
      return;
    }
    if (group.active) {
      groupArray.push(group as MenuProps);
    }
  });
  const menuNames = groupArray.map((menu) => menu.name);
  const filteredNewProducts = adminNewProducts.filter(
    (menu) => !menuNames.includes(menu.menuName) && menu.active
  );
  return { menus: groupArray, newProducts: filteredNewProducts };
};
