import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Typography, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const StyledListItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  width: 100%;
  position: relative;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const FlexColumn = styled.div<{ sideBarOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ sideBarOpen }) => (sideBarOpen ? "100%" : "min-content")};
  padding-left: ${({ theme, sideBarOpen }) =>
    !sideBarOpen ? theme.spacings.xs3 : 0};
`;

const ItemSkeleton = styled(Skeleton)`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 10%;
  margin-bottom: -3%;
`;

const NotificationDescription = styled.div<{ sideBarOpen?: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ theme, sideBarOpen }) =>
    sideBarOpen ? theme.spacings.s : theme.spacings.xs};
`;

const CommonContainer = css<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: ${({ $sideBarOpen }) =>
    $sideBarOpen ? "flex-start" : "center"};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  padding: ${({ theme, $hasNotification, $sideBarOpen }) =>
    `${
      $hasNotification && !$sideBarOpen
        ? theme.spacings.xs5
        : theme.spacings.xs4
    } ${theme.spacings.xs}`};

  ${({ $active }) =>
    $active &&
    css`
      p {
        color: ${({ theme }) => theme.colors.secondary.dark.dark5};
      }

      svg {
        stroke: ${({ theme }) => theme.colors.secondary.dark.dark5} !important;
      }
    `}

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
  }
`;

const StyledLink = styled(NavLink)<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  ${CommonContainer};
`;

const StyledRedirectLink = styled.a<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  ${CommonContainer};
`;

const MenuContainer = styled.div<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  ${CommonContainer};
`;

const IconContainer = styled.div<{
  open: boolean;
  $sideBarOpen: boolean;
}>`
  display: flex;
  margin-left: 6px;
  ${({ $sideBarOpen }) => !$sideBarOpen && "flex: 0; display: none;"}
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease, opacity 0.3s linear;
`;

const StyledIcon = styled(Icons)<{
  primary: boolean;
  sideBarOpen?: boolean;
  hasNotification?: boolean;
}>`
  ${({ theme, sideBarOpen, hasNotification }) =>
    !sideBarOpen &&
    `
    padding: ${!hasNotification ? `0 ${theme.spacings.xs3}` : 0};
    `};

  svg {
    fill: transparent;
    stroke: ${({ theme, primary }) =>
      primary
        ? theme.colors.neutral.dark.dark5
        : theme.colors.neutral.light.light4};
  }
`;

const Title = styled(Typography)<{ $sideBarOpen: boolean }>`
  display: block;
  display: -webkit-box;
  opacity: ${({ $sideBarOpen }) => ($sideBarOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in;
  overflow: hidden;
  justify-content: flex-start;
  margin: 0px 1px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};

  && {
    margin: ${({ $sideBarOpen }) => ($sideBarOpen ? "0px 0px 0px 6px" : "0px")};
    font-weight: 600;
    line-height: 1.2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const SubMenus = styled.div<{ $active: boolean }>`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: all 0.5s ease;
`;

const StyledSubMenuUl = styled.ul`
  margin: ${({ theme }) =>
    `${theme.spacings.xs4} 0 ${theme.spacings.xs4} ${theme.spacings.s}`};
  border-left: ${({ theme }) =>
    `${theme.borders.width.s} solid ${theme.colors.neutral[80]}`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

const FlexCenter = styled.div<{ $sideBarOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
  opacity: ${({ $sideBarOpen }) => ($sideBarOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in;

  ${({ $sideBarOpen }) =>
    !$sideBarOpen &&
    `
     width: 0;
     height: 0;
     flex: 0;
    `};
`;

const NewTag = styled.div`
  padding: 4px 12px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

const NewTagText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  color: ${({ theme }) => theme.colors.secondary[50]};

  && {
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`;

const NewItemIcon = styled(Icons)<{ $sideBarOpen: boolean }>`
  position: absolute;
  margin-top: -19px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  opacity: ${({ $sideBarOpen }) => ($sideBarOpen ? 0 : 1)};
  transition: opacity 0.3s ease-in;
  color: rgb(242, 13, 122);

  svg {
    stroke: rgb(242, 13, 122);
  }
`;

const FlaggedActive = styled.div`
  width: 4px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.secondary[50]};
  border-radius: ${({ theme }) => theme.borders.radius.pill};
`;

export {
  StyledListItem,
  StyledIcon,
  ItemWrapper,
  StyledLink,
  StyledRedirectLink,
  MenuContainer,
  Title,
  IconContainer,
  SubMenus,
  StyledSubMenuUl,
  FlexColumn,
  FlexCenter,
  NewTag,
  NewTagText,
  NewItemIcon,
  ItemSkeleton,
  NotificationDescription,
  FlaggedActive,
};
