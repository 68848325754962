import { track } from "../../../../utils/eventTracking";

export const getEmployeeMenu = () => ({
  menuItems: {
    home: {
      name: "home",
      route: "/home/employee",
      icon: "IconHome",
      active: false,
    },
    marketplace: {
      icon: "IconBuildingStore",
      name: "marketplace",
      route: "/marketplace",
      active: false,
    },
    cards: {
      name: "employee.cards",
      route: "/cards/me",
      icon: "IconCreditCard",
      active: false,
    },
    wallet: {
      name: "employee.wallet",
      route: "/corporateCard/wallet",
      icon: "IconWallet",
      active: false,
    },
    statement: {
      name: "employee.statement",
      icon: "IconReceipt",
      route: "/corporateCard/statement",
      active: false,
    },
    benefitStatement: {
      name: "employee.benefitStatement",
      icon: "IconReceipt",
      route: "/corporateCard/statement",
      active: false,
    },
    expenseCorp: {
      name: "employee.corporateExpenses.title",
      icon: "IconReceipt2",
      active: false,
      subMenus: {
        accountability: {
          name: "employee.corporateExpenses.accountability",
          route: "/expenseManagement/expenses",
          active: false,
        },
        advance: {
          name: "employee.corporateExpenses.advance",
          route: "/expenseManagement/advances",
          active: false,
        },
        analytics: {
          name: "employee.corporateExpenses.analytics",
          route: "/expenseManagement/analytics",
          active: false,
        },
      },
    },
    expenseLifecycle: {
      name: "employee.expenseLifecycle.title",
      icon: "IconReceipt2",
      active: false,
      subMenus: {
        statement: {
          name: "employee.expenseLifecycle.statement",
          route: "/expense-lifecycle/statement",
          active: false,
        },
        reimbursements: {
          name: "employee.expenseLifecycle.reimbursements",
          route: "/expense-lifecycle/reimbursements",
          active: false,
        },
      },
    },
    expenseTravel: {
      name: "employee.corporateTravels.title",
      icon: "IconPlaneTilt",
      route: "",
      active: false,
      subMenus: {
        search: {
          name: "employee.corporateTravels.search",
          route: "/travel/sale",
          active: false,
        },
        requests: {
          name: "employee.corporateTravels.requests",
          route: "/travel/list",
          active: false,
        },
        approvals: {
          name: "employee.corporateTravels.approvals",
          route: "/travel/approvals",
          active: false,
        },
        monitoring: {
          name: "employee.corporateTravels.monitoring",
          route: "/travel/monitoring",
          active: false,
        },
        reports: {
          name: "employee.corporateTravels.reports",
          route: "/travel/reports",
          active: false,
        },
      },
    },
    timeAndAttendance: {
      name: "employee.timeAndAttendance.title",
      icon: "IconClock",
      active: false,
      route: "",
      subMenus: {
        timeClock: {
          name: "employee.timeAndAttendance.timeClock",
          route: "/time-and-attendance/clockin",
          active: false,
        },
        timeSheet: {
          name: "employee.timeAndAttendance.timeSheet",
          route: "/time-and-attendance/time-sheet",
          active: false,
        },
        workshift: {
          name: "employee.timeAndAttendance.workshift",
          route: "/time-and-attendance/workshift",
          active: false,
        },
        vacation: {
          name: "employee.timeAndAttendance.vacation",
          route: "/time-and-attendance/vacations",
          active: false,
        },
      },
    },
    performance: {
      name: "employee.performance.title",
      icon: "IconRocket",
      route: "/performance",
      active: false,
      subMenus: {
        cycles: {
          name: "employee.performance.cycles",
          route: "/performance/my-cycles",
          active: false,
        },
        feedbacks: {
          name: "employee.performance.feedbacks",
          route: "/performance/my-feedbacks",
          active: false,
        },
        idp: {
          name: "employee.performance.idp",
          route: "/performance/my-idps",
          active: false,
        },
      },
    },
    recruitment: {
      name: "employee.recruitment.title",
      icon: "IconUserPlus",
      active: false,
      route: "",
      isNew: true,
      subMenus: {
        jobOpenings: {
          name: "employee.recruitment.jobOpenings",
          route: "/recruitment/my-job-openings",
          active: false,
          onClick: () =>
            track({
              type: "menu_clicked",
              screen: "employee_recruitment_jobOpenings",
              businessUnit: "recruitment",
            }),
        },
      },
    },
    training: {
      name: "employee.training.title",
      icon: "IconSchool",
      route: "/lms/my-courses",
      active: false,
    },
    communications: {
      name: "employee.communication.title",
      icon: "IconSpeakerphone",
      route: "",
      active: false,
      subMenus: {
        companyMessages: {
          name: "employee.communication.companyMessages",
          route: "/",
          active: false,
        },
        companySurveys: {
          name: "employee.communication.companySurveys",
          route: "/engagement/my-surveys",
          active: false,
          onClick: () =>
            track({
              type: "menu_clicked",
              screen: "employee_engagement_surveys",
              businessUnit: "engagement",
            }),
        },
      },
    },
    orgchart: {
      name: "employee.orgChart.title",
      icon: "IconHierarchy",
      route: "/orgchart",
      active: false,
    },
    profileSettings: {
      name: "employee.profileSettings.title",
      icon: "IconSettings",
      route: "/employees/person",
      active: false,
    },
  },
  newProducts: [],
});

export type EmployeeMenuType = ReturnType<typeof getEmployeeMenu>;
