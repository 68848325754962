import { UsuarioFolhaCerta } from "../../api";

import { track } from "../../utils/eventTracking";
import { getTimeAndAttendanceMenu } from "./timeAndAttendenceMenuGroup";
import { MenuGroupsProps, MenuProps, PeopleContractModules } from "./types";

export function getPeopleMenuGroup(
  folhaCertaUser: UsuarioFolhaCerta | null,
  peopleContractModules?: PeopleContractModules
): MenuGroupsProps {
  const filteredModules = Array.from(
    new Set(peopleContractModules?.modules ?? [])
  ) as string[];

  const menuOrder = [
    "recruitment",
    "flows",
    "lms",
    "engagement",
    "performance",
    "orgchart",
    "analytics",
    "time-and-attendance",
  ] as const;

  const peopleMenuGroup: MenuGroupsProps = {
    name: "people.title",
    id: "flash_people",
    menus: menuOrder
      .filter((moduleName) => filteredModules?.includes(moduleName))
      .map((moduleName) => {
        switch (moduleName) {
          case "lms":
            return {
              name: "people.lms.courses",
              icon: "IconSchool",
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "people_lms",
                  businessUnit: "lms",
                }),
              subMenus: [
                {
                  name: "people.lms.myCourses",
                  route: "/lms/my-courses",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_mycourses",
                      businessUnit: "lms",
                    }),
                },
                {
                  name: "people.lms.manageCourses",
                  route: "/lms/manage-courses",
                  permissions: ["people_lms_view_manage_courses"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_managecourses",
                      businessUnit: "lms",
                    }),
                },
              ],
            };
          case "engagement":
            return {
              name: "people.engagement.engagement",
              icon: "IconSpeakerphone",
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "people_engagement",
                  businessUnit: "engagement",
                }),
              subMenus: [
                {
                  name: "people.engagement.mySurveys",
                  route: "/engagement/my-surveys",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_my-surveys",
                      businessUnit: "engagement",
                    }),
                },
                {
                  name: "people.engagement.manageSurveys",
                  route: "/engagement/survey",
                  permissions: ["people_engagements_view_researches"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_surveys",
                      businessUnit: "engagement",
                    }),
                },
                {
                  name: "people.engagement.manageCommunications",
                  route: "/engagement/communication",
                  permissions: ["people_engagements_view_communications"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_communication",
                      businessUnit: "engagement",
                    }),
                },
              ],
            };
          case "orgchart":
            return {
              name: "people.orgchart",
              route: "/orgchart",
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "people_orgchart",
                  businessUnit: "orgchart",
                }),
              icon: "IconHierarchy",
            };
          case "analytics":
            return {
              name: "people.analytics",
              route: "/analytics",
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "people_analytics",
                  businessUnit: "analytics",
                }),
              icon: "IconChartPie",
              permissions: ["people_insights_view"],
            };
          case "flows":
            return {
              name: "people.manageFlows.flows",
              permissions: [
                "people_flows_view_admission_kanban",
                "people_flows_view_resignation_kanban",
                "people_flows_view_edit_recognitions",
              ],
              icon: "IconLayoutKanban",
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "people_flows",
                  businessUnit: "flows",
                }),
              subMenus: [
                {
                  name: "people.manageFlows.hiring",
                  route: "/flows/hiring",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_hiring",
                      businessUnit: "flows",
                    }),
                  permissions: [
                    "people_flows_view_admission_kanban",
                    "people_flows_create_candidate",
                  ],
                },
                {
                  name: "people.manageFlows.resignation",
                  route: "/flows/resignation",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_resignation",
                      businessUnit: "flows",
                    }),
                  permissions: ["people_flows_view_resignation_kanban"],
                },
                {
                  name: "people.manageFlows.recognition",
                  route: "/recognition",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_recognition",
                      businessUnit: "flows",
                    }),
                  permissions: ["people_flows_view_edit_recognitions"],
                  unleashToggleName: "FLASH_OS_PEOPLE_RECOGNITION",
                },
              ],
            };
          case "performance":
            return {
              name: "people.performance.performance",
              icon: "IconChartLine",
              subMenus: [
                {
                  name: "people.performance.manageCycles",
                  route: "/performance/manage-cycles",
                  permissions: ["performance_view_cycles"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "admin_performance_cycles",
                      businessUnit: "performance",
                    }),
                },
                {
                  name: "people.performance.myCycles",
                  route: "/performance/my-cycles",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "employee_performance_cycles",
                      businessUnit: "performance",
                    }),
                },
                {
                  name: "people.performance.manageCriterials",
                  route: "/performance/criterials",
                  permissions: ["performance_view_criterials"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "admin_performance_ratingcriterials",
                      businessUnit: "performance",
                    }),
                },
                {
                  name: "people.performance.manageEvaluations",
                  route: "/performance/evaluations/v2",
                  permissions: ["performance_review_view"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "admin_performance_evaluations",
                      businessUnit: "performance",
                    }),
                },
                {
                  name: "people.performance.manageFeedbacks",
                  route: "/performance/manage-feedbacks",
                  permissions: ["performance_view_feedbacks"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "admin_performance_feedbacks",
                      businessUnit: "performance",
                    }),
                },
                {
                  name: "people.performance.myFeedbacks",
                  route: "/performance/my-feedbacks",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "employee_performance_feedbacks",
                      businessUnit: "performance",
                    }),
                },
                {
                  name: "people.performance.manageIdp",
                  route: "performance/manage-idps",
                  permissions: ["performance_view_idps"],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "admin_performance_idps",
                      businessUnit: "performance",
                    }),
                },
                {
                  name: "people.performance.myIdp",
                  route: "performance/my-idps",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "employee_performance_idps",
                      businessUnit: "performance",
                    }),
                },
              ],
            };
          case "time-and-attendance":
            return getTimeAndAttendanceMenu(folhaCertaUser);
          case "recruitment":
            return {
              name: "people.recruitment.recruitment",
              icon: "IconUserPlus",
              isNew: true,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "people_recruitment",
                  businessUnit: "recruitment",
                }),
              subMenus: [
                {
                  name: "people.recruitment.jobOpenings",
                  route: "/recruitment/job-openings",
                  permissions: [
                    "people_recruitment_manage_job_openings",
                    "people_recruitment_list_job_openings",
                  ],
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "people_recruitment_jobopenings",
                      businessUnit: "recruitment",
                    }),
                  unleashToggleName: "FLASH_OS_PEOPLE_RECRUITMENT",
                },
                {
                  name: "people.recruitment.myJobOpenings",
                  route: "/recruitment/my-job-openings",
                  onClick: () =>
                    track({
                      type: "menu_clicked",
                      screen: "employee_recruitment_myJobOpenings",
                      businessUnit: "recruitment",
                    }),
                  unleashToggleName: "FLASH_OS_PEOPLE_RECRUITMENT",
                },
              ],
            };
          default:
            return null;
        }
      })
      .filter((menu) => menu !== null) as MenuProps[],
  };

  return peopleMenuGroup;
}
