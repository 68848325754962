import { Typography, TextField, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledModalTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledModalDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 95%;
  margin-bottom: 20px;
`;

export const StyledCompanyItem = styled.li<{
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
  border: ${({ theme, active }) =>
    active
      ? `solid 1px ${theme.colors.secondary[95]}`
      : `solid 1px ${theme.colors.neutral[90]}`};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.secondary[95] : theme.colors.neutral[100]};
  cursor: pointer;
`;

export const StyledCompanyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCompanyItemTitle = styled(Typography)<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.secondary[50] : theme.colors.neutral[30]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const StyledCompanyItemDescription = styled(Typography)<{
  active?: boolean;
}>`
  color: ${({ theme, active }) =>
    active ? theme.colors.secondary[50] : theme.colors.neutral[40]};
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  height: 100%;
  max-height: 300px;
  margin-bottom: 10px;
  padding-right: 10px;
  min-height: 300px;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  color: ${(props) => props.theme.colors.neutral[30]};
  font-weight: 600;

  & .MuiInputBase-root {
    border: none !important;
  }

  & .MuiInputBase-input {
    padding: 2px 10px !important;
    font-size: 14px;
  }
`;

export const StyledSearchInputContainer = styled.div`
  padding: 6px 12px;
  position: relative;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.pill};
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const StyledSearchInputIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  min-height: 300px;
`;

export const StyledEmptyStateIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledEmptyStateTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  text-align: center;
`;

export const StyledEmptyStateDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  text-align: center;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
