import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import {
  Title,
  StyledContainer,
  HeaderContainer,
  StyledIcon,
  StyledIconContainer,
  StyledHorizontalBox,
  StyledVerticalBox,
  Description,
  StyledProfileItemContainer,
  StyledProfileItemsContainer,
  StyledIconWrapper,
  StyledExpandIconContainer,
  StyledColapsedContainer,
} from "./styles";
import { SelectedProfile } from "../../types";

const ProfileItem = ({
  isSelected,
  title,
  description,
  iconName,
  onProfileSelect,
}: {
  isSelected?: boolean;
  title: string;
  description: string;
  iconName: IconTypes;
  onProfileSelect: () => void;
}) => {
  return (
    <StyledProfileItemContainer
      isSelected={isSelected}
      onClick={onProfileSelect}
    >
      <StyledHorizontalBox>
        <StyledIcon
          name={iconName}
          size={32}
          neutral={!isSelected}
        ></StyledIcon>
        <StyledVerticalBox>
          <Title variant="body4" neutral={!isSelected}>
            {title}
          </Title>
          <Description variant="caption" neutral={!isSelected}>
            {description}
          </Description>
        </StyledVerticalBox>
      </StyledHorizontalBox>
    </StyledProfileItemContainer>
  );
};

const ProfileToggle = ({
  isExpanded,
  selectedProfile,
  onHeaderClick,
  onSelectProfile,
  menuOpen,
  hasAdminProfile,
}: {
  isExpanded: boolean;
  menuOpen: boolean;
  selectedProfile: SelectedProfile;
  onHeaderClick: () => void;
  onSelectProfile: (profile: SelectedProfile) => void;
  hasAdminProfile: boolean;
}) => {
  const isAdmin = selectedProfile === "admin";
  const retractedTitle = isAdmin ? "Perfil da Empresa" : "Meu perfil";
  const currentSelectProfileIcon = isAdmin
    ? "IconBuildingCommunity"
    : "IconUser";

  if (!menuOpen)
    return (
      <StyledColapsedContainer>
        <StyledIconWrapper>
          <StyledIcon name={currentSelectProfileIcon} size={16}></StyledIcon>
        </StyledIconWrapper>
      </StyledColapsedContainer>
    );

  return (
    <StyledContainer className="onboarding-change-profile">
      <HeaderContainer onClick={onHeaderClick} isExpanded={isExpanded}>
        <StyledHorizontalBox>
          <StyledIconContainer isExpanded={isExpanded}>
            <StyledIconWrapper>
              <StyledIcon
                name={currentSelectProfileIcon}
                size={16}
              ></StyledIcon>
            </StyledIconWrapper>
          </StyledIconContainer>
          <StyledVerticalBox>
            <Title variant="body4">
              {isExpanded ? "Selecione um perfil" : retractedTitle}
            </Title>
            {!isExpanded && (
              <Description variant="caption">
                {isAdmin ? "Admin ou gestor" : "Colaborador"}
              </Description>
            )}
          </StyledVerticalBox>
        </StyledHorizontalBox>
        <StyledExpandIconContainer isExpanded={isExpanded}>
          <StyledIcon name="IconChevronDown" size={18}></StyledIcon>
        </StyledExpandIconContainer>
      </HeaderContainer>
      <StyledProfileItemsContainer isExpanded={isExpanded}>
        {hasAdminProfile && (
          <ProfileItem
            isSelected={isAdmin}
            title="Perfil da empresa"
            description="Acesse as ferramentas de gestão da empresa"
            iconName={isAdmin ? "IconCircleCheck" : "IconBuildingCommunity"}
            onProfileSelect={() => onSelectProfile("admin")}
          ></ProfileItem>
        )}
        <ProfileItem
          isSelected={!isAdmin}
          title="Meu perfil"
          description="Use as funcionalidades da sua conta de colaborador"
          iconName={!isAdmin ? "IconCircleCheck" : "IconUser"}
          onProfileSelect={() => onSelectProfile("employee")}
        ></ProfileItem>
      </StyledProfileItemsContainer>
    </StyledContainer>
  );
};

export default ProfileToggle;
