import { MenuGroupsProps, MenuProps } from "./types";
import { checkUnleashToggle } from "../../utils/checkUnleashPermission";
import { setEventTracking } from "../../utils/eventTracking";
import { IToggle } from "unleash-proxy-client";

const UNLEASH_KEYS = {
  BENEFIT_MANAGEMENT: "EVOLUTION_HROS_BENEFIT_MANAGEMENT_XP",
  SIMPLE_ASSIGNMENT: "EVOLUTION_BENEFIT_SIMPLE_ASSIGNMENT_XP",
  PLACED_ORDERS: "BO_PLACED_ORDERS",
  POINTS_MENU: "FLASH_OS_POINTS_MENU_ENABLED",
  PAYROLL_LOAN: "FLASH_OS_PAYROLL_LOAN_MENU_ENABLED",
};

function shouldShowMenuItem(menuItem: MenuProps, unleashToggles: IToggle[]) {
  if (menuItem.unleashPermissionKey) {
    return checkUnleashToggle(menuItem.unleashPermissionKey, unleashToggles);
  }

  if (menuItem.unleashPermissionDeniedKey) {
    return !checkUnleashToggle(
      menuItem.unleashPermissionDeniedKey,
      unleashToggles
    );
  }

  return true;
}

function updateMenuItem(
  menuItem: MenuProps,
  unleashToggles: IToggle[]
): MenuProps {
  switch (menuItem.name) {
    case "benefits.yourBenefits":
      const isBenefitManagement = checkUnleashToggle(
        UNLEASH_KEYS.BENEFIT_MANAGEMENT,
        unleashToggles
      );

      return {
        ...menuItem,
        route: isBenefitManagement
          ? "/benefit-management"
          : "/benefits/dashboard/company/benefits",
      };

    default:
      return menuItem;
  }
}

export function getBenefitsMenuGroup(
  isLoading: boolean,
  unleashToggles: IToggle[]
): MenuGroupsProps {
  const initialMenuGroup: MenuGroupsProps = {
    name: "benefits.title",
    id: "flash_benefits",
    menus: [
      {
        name: "benefits.yourBenefits",
        route: "/benefits/dashboard/company/benefits",
        icon: "IconColorSwatch",
        isLoading,
        permissions: [
          "benefits_employees_ordercreate",
          "benefits_employees_orderbalancecreate",
        ],
      },
      {
        name: "benefits.simpleAssignment",
        route: "/simple-assignment",
        icon: "IconUserPlus",
        isLoading,
        permissions: [
          "benefits_simpleassignment_view",
          "benefits_external_benefits_assign_view",
        ],
        unleashPermissionKey: UNLEASH_KEYS.SIMPLE_ASSIGNMENT,
      },
      {
        name: "benefits.employees",
        route: "/benefits/dashboard/company/employees",
        icon: "IconShoppingCart",
        isLoading,
        permissions: [
          "benefits_employees_cardsorderview",
          "benefits_employees_awardsorderview",
          "benefits_employees_home_officeorderview",
          "benefits_employees_benefitsedit",
          "benefits_employees_discountedit",
          "benefits_employees_employeesadd",
          "benefits_employees_employeesedit",
          "benefits_employees_employeesremove",
          "benefits_employees_notificationresend",
          "benefits_employees_ordercreate",
          "benefits_employees_orderbalancecreate",
        ],
        unleashPermissionDeniedKey: UNLEASH_KEYS.SIMPLE_ASSIGNMENT,
      },
      {
        name: "benefits.placedOrders",
        route: "/benefit-order/orders",
        icon: "IconPackage",
        isLoading,
        permissions: [
          "benefits_employees_ordercreate",
          "benefits_employees_orderbalancecreate",
        ],
        unleashPermissionKey: UNLEASH_KEYS.PLACED_ORDERS,
        onClick: () => setEventTracking("placed_orders_clicked", "benefits"),
      },
      {
        name: "benefits.deposits",
        route: "/benefits/dashboard/company/deposits",
        icon: "IconReceipt2",
        isLoading,
        permissions: [
          "benefits_deposits_pageview",
          "benefits_deposits_reportview",
        ],
        unleashPermissionDeniedKey: UNLEASH_KEYS.SIMPLE_ASSIGNMENT,
      },
      {
        name: "finance.title",
        icon: "IconBuildingBank",
        isLoading,
        subMenus: [
          {
            name: "finance.payments",
            route: "/benefits/dashboard/company/payments",
            permissions: [
              "benefits_billets_pageview",
              "benefits_billets_reportview",
              "benefits_billets_billetcancel",
            ],
          },
          {
            name: "finance.balance",
            route: "/benefits/dashboard/company/balance",
            permissions: [
              "benefits_billets_pageview",
              "benefits_billets_reportview",
              "benefits_billets_billetcancel",
            ],
          },
        ],
      },
      {
        name: "benefits.points.title",
        icon: "IconMedal",
        unleashPermissionKey: UNLEASH_KEYS.POINTS_MENU,
        subMenus: [
          {
            name: "benefits.points.customBenefits",
            route: "/benefits/dashboard/company/custom-benefits",
            permissions: ["benefits_employees_benefitsedit"],
          },
          {
            name: "benefits.points.policies",
            route: "/benefits/dashboard/company/points",
            permissions: ["benefits_employees_benefitsedit"],
          },
          {
            name: "benefits.points.policiesAssignment",
            route: "/benefits/dashboard/points/employees",
            permissions: ["benefits_employees_benefitsedit"],
          },
        ],
      },
      {
        name: "reports",
        route: "/benefits/dashboard/company/reports",
        icon: "IconPresentation",
        isLoading,
        permissions: ["benefits_reports_pageview"],
      },
      // Removed because it doesn't have the proper permission yet
      // {
      //   name: "benefits.api",
      //   route: "/benefits/dashboard/company/api",
      //   icon: "IconKey",
      // },
      {
        name: "benefits.payrollLoan",
        route: "/payroll-loan?source=menu",
        icon: "IconBusinessplan",
        isLoading,
        permissions: [
          "payroll_loan_viewer",
          "payroll_loan_manager",
          "core_contract_products",
          "benefits_employees_ordercreate",
        ],
        isNew: true,
        unleashPermissionKey: UNLEASH_KEYS.PAYROLL_LOAN,
      },
    ],
  };

  const filteredMenus = initialMenuGroup.menus
    .filter((menuItem) => shouldShowMenuItem(menuItem, unleashToggles))
    .map((menuItem) => updateMenuItem(menuItem, unleashToggles));

  return {
    ...initialMenuGroup,
    menus: filteredMenus,
  };
}
