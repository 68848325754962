import {
  IPermissionsFlags,
  getPermissionsFlags,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";

export function useUnleashToggles({ companyId }: { companyId: string }) {
  const [flags, setFlags] = useState<IPermissionsFlags["flags"]>([]);
  const getUnleashToggle = async () => {
    const { flags } = await getPermissionsFlags(companyId, "", "");
    setFlags(flags || []);
  };

  useEffect(() => {
    getUnleashToggle();
  }, [companyId]);
  return flags;
}
