import { useEffect, useMemo, useContext } from "react";
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";
import { useAuth } from "@flash-hros/auth-helper";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Context } from "../../context";
import { getCompany, getPeopleContractModules } from "../../api";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import {
  Company,
  MenuGroupsProps,
  MenuProps,
  MenusWithDividers,
  Notifications,
  SelectedProfile,
} from "./types";
import { useNotificationsMenu } from "../../hooks/useNotificationsMenu";
import { mapEmployeeMenuFromV1 } from "./MenuGroups/Employee/mapEmployeeMenuFromV1";
import { mapAdminMenuFromV1 } from "./MenuGroups/Admin/mapAdminMenuFromV1";
import { useNavigate } from "react-router-dom";
import { useSelectedView } from "../../hooks/useSelectedProfile";
import { useCheckActiveModule as useCheckExpenseModule } from "@flash-tecnologia/expense-web-activation-utils";
import { MenuV2Arguments } from "./types/menu-v2-arguments";
import { useMountedMenuGroup } from "../../hooks/useMenuGroups";
import { refetchExpenseMenus } from "./MenuGroups/setup/set-up-expense-lifecycle-menus";

const MenuV2 = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);
  const accessToken = useAuth((state) => state.accessToken);
  const { companies } = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const isExpenseLifecycleEnabled = useCheckExpenseModule("lifecycle");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const companyPermissions = useMemo(() => {
    if (companies && selectedCompany?.id) {
      const selectedCompanyPermissions = companies.find(
        (company) => company.id === selectedCompany.id
      );
      return selectedCompanyPermissions;
    }
    return null;
  }, [companies, selectedCompany]);
  const hasSettings =
    companyPermissions?.permissions.includes("core_manage_company_settings") ||
    companyPermissions?.permissions.includes("*");

  const { menuGroups } = useMountedMenuGroup();

  const notifications: Notifications = useNotificationsMenu(menuGroups);

  const menuV2Args: MenuV2Arguments = {
    isExpenseLifecycleEnabled,
    companyPermissions,
  };

  const employeeMenuGroups = mapEmployeeMenuFromV1(menuGroups, menuV2Args);

  const adminMenuGroups = mapAdminMenuFromV1({
    args: menuV2Args,
    menuV1Groups: menuGroups,
    hasSettings,
  });

  const hasAdminProfile = adminMenuGroups.menus.length > 1;

  const { selectedProfile, handleSelectedView } = useSelectedView(
    hasAdminProfile,
    adminMenuGroups,
    employeeMenuGroups
  );

  useEffect(() => {
    (async () => {
      await getCompany({ dispatch });
    })();
  }, [accessToken]);

  useEffect(() => {
    const checkPeopleContractModules = async ({
      detail,
    }: CustomEvent<Company>) => {
      const { contractStatus } = await getPeopleContractModules(detail?.id);
      useAuth.setState({ contractStatus });
    };

    const onAcquisitionCompanyModulesUpdated = (
      event: CustomEvent<Company>
    ) => {
      checkPeopleContractModules(event);
      refetchExpenseMenus(event?.detail?.id);
    };

    window.addEventListener(
      "header_company_selected",
      checkPeopleContractModules
    );

    window.addEventListener(
      "acquisition_company_modules_updated",
      onAcquisitionCompanyModulesUpdated
    );

    return () => {
      window.removeEventListener(
        "header_company_selected",
        checkPeopleContractModules
      );
      window.removeEventListener(
        "acquisition_company_modules_updated",
        onAcquisitionCompanyModulesUpdated
      );
    };
  }, []);

  const handleSelectProfile = (profile: SelectedProfile) => {
    if (profile !== selectedProfile) {
      handleSelectedView(profile);

      if (profile === "admin") {
        navigate("/home/admin");
        return;
      }

      navigate("/home/employee");
    }
  };

  const visibleMenus =
    selectedProfile === "admin" ? adminMenuGroups : employeeMenuGroups;
  const visibleMenusWithDividers: MenusWithDividers = [];

  visibleMenus.menus.forEach(
    (item: MenuGroupsProps | MenuProps, index: number) => {
      const previousItemIsGroup =
        index === 0 ? false : "menus" in visibleMenus.menus[index - 1];
      const currentItemIsGroup = !!("menus" in item);
      if (
        (currentItemIsGroup && !previousItemIsGroup) ||
        item.name === "employee.profileSettings.title"
      ) {
        visibleMenusWithDividers.push("topDivider");
      }
      visibleMenusWithDividers.push(item);
      if (currentItemIsGroup && visibleMenus.menus[index + 1]) {
        if ("menus" in visibleMenus.menus[index + 1]) {
          visibleMenusWithDividers.push("centerDivider");
        } else {
          visibleMenusWithDividers.push("bottomDivider");
        }
      }
    }
  );

  if (isMobile) {
    return (
      <Mobile
        menuGroups={{
          menus: visibleMenusWithDividers,
          newProducts: visibleMenus.newProducts,
        }}
        hasSettings={hasSettings}
        notifications={notifications}
        selectedProfile={selectedProfile}
        onSelectProfile={handleSelectProfile}
        hasAdminProfile={hasAdminProfile}
      />
    );
  }

  return (
    <Desktop
      menuGroups={{
        menus: visibleMenusWithDividers,
        newProducts: visibleMenus.newProducts,
      }}
      notifications={notifications}
      selectedProfile={selectedProfile}
      onSelectProfile={handleSelectProfile}
      hasAdminProfile={hasAdminProfile}
    />
  );
};

export default MenuV2;
