import { track } from "../../../../utils/eventTracking";

export const getAdminMenu = () => ({
  menuItems: {
    home: {
      name: "admin.home",
      route: "/home/admin",
      icon: "IconHome",
      active: false,
    },
    users: {
      name: "admin.users",
      route: "/employees",
      icon: "IconUsers",
      active: false,
    },
    cards: {
      name: "admin.cards",
      route: "/cards",
      icon: "IconCreditCard",
      active: false,
    },
    marketplace: {
      icon: "IconHeartHandshake",
      name: "admin.marketplace",
      route: "/marketplace",
      active: false,
    },
    documentManagement: {
      icon: "IconFolder",
      name: "admin.documentManagement",
      route: "/document-management",
      active: false,
    },
    reports: {
      icon: "IconPresentation",
      name: "admin.reports",
      route: "/reports",
      active: false,
    },
    appStore: {
      icon: "IconBuildingStore",
      name: "admin.appStore",
      route: "/acquisition/app-store",
      isNew: true,
      active: false,
    },
    benefits: {
      name: "admin.benefits.title",
      active: false,
      menus: {
        companyBenefits: {
          active: false,
          name: "admin.benefits.companyBenefits.title",
          route: "/benefit-management",
          icon: "IconColorSwatch",
          subMenus: {
            overView: {
              active: false,
              name: "admin.benefits.companyBenefits.overView",
              route: "/benefits/dashboard/company/benefits",
            },
            setup: {
              active: false,
              name: "admin.benefits.companyBenefits.setup",
              route: "/simple-assignment",
            },
            orders: {
              active: false,
              name: "admin.benefits.companyBenefits.orders",
              route: "/benefits/dashboard/company/employees",
            },
            placedOrders: {
              active: false,
              name: "admin.benefits.companyBenefits.placedOrders",
              route: "/benefit-order/orders",
            },
            deposits: {
              active: false,
              name: "admin.benefits.companyBenefits.deposits",
              route: "/benefits/dashboard/company/deposits",
            },
          },
        },
        finance: {
          active: false,
          name: "admin.benefits.finance.title",
          icon: "IconCoin",
          subMenus: {
            billets: {
              name: "admin.benefits.finance.billets",
              route: "/benefits/dashboard/company/payments",
              active: false,
            },
            cash: {
              name: "admin.benefits.finance.cash",
              route: "/benefits/dashboard/company/balance",
              active: false,
            },
          },
        },
        payrollLoan: {
          name: "admin.benefits.payrollLoan",
          route: "/payroll-loan?source=menu",
          icon: "IconBusinessplan",
          active: false,
        },
        points: {
          active: false,
          name: "admin.benefits.points.title",
          icon: "IconMedal",
          subMenus: {
            customBenefits: {
              active: false,
              name: "admin.benefits.points.customBenefits",
              route: "/benefits/dashboard/company/custom-benefits",
            },
            policies: {
              active: false,
              name: "admin.benefits.points.policies",
              route: "/benefits/dashboard/company/points",
            },
            policiesAssignment: {
              active: false,
              name: "admin.benefits.points.policiesAssignment",
              route: "/benefits/dashboard/points/employees",
            },
          },
        },
        reports: {
          name: "admin.benefits.reports",
          route: "/benefits/dashboard/company/reports",
          icon: "IconPresentation",
          active: false,
        },
      },
    },
    expense: {
      active: false,
      name: "admin.expense.title",
      menus: {
        dashboard: {
          active: false,
          name: "admin.expense.dashboard",
          icon: "IconDashboard",
          route: "/expense-dashboard",
          isNew: true,
        },
        approvals: {
          name: "admin.expense.approvals",
          icon: "IconThumbUp",
          route: "/expenseManagement/approvals",
          active: false,
        },
        corporateCard: {
          active: false,
          name: "admin.expense.corporateCard.title",
          icon: "IconWallet",
          route: "",
          subMenus: {
            balance: {
              name: "admin.expense.corporateCard.balance",
              route: "/corporateCard/management",
              active: false,
            },
            cards: {
              name: "admin.expense.corporateCard.cards",
              route: "/corporateCard/home",
              active: false,
            },
            accounts: {
              name: "admin.expense.corporateCard.accounts",
              route: "/corporateCard/user-accounts",
              active: false,
            },
            statement: {
              name: "admin.expense.corporateCard.statement",
              route: "/expense/statement",
              active: false,
            },
            closing: {
              name: "admin.expense.corporateCard.closing",
              route: "/expense-lifecycle/admin/statement",
              active: false,
            },
          },
        },
        reimbursementsClosing: {
          name: "admin.expense.reimbursementClosing",
          icon: "IconCalculator",
          route: "/expense-lifecycle/admin/reimbursements",
          active: false,
        },
        externalCards: {
          name: "admin.expense.externalCards",
          icon: "IconCreditCard",
          route: "/corporateCard/externalCards",
          active: false,
        },
        suppliers: {
          name: "admin.expense.suppliers",
          icon: "IconBuildingStore",
          route: "/expense/company-payables/vendors",
          active: false,
        },
        travel: {
          name: "admin.expense.travel.title",
          icon: "IconPlaneTilt",
          route: "",
          active: false,
          subMenus: {
            management: {
              name: "admin.expense.travel.management",
              route: "/travel/list",
              active: false,
            },
            reports: {
              name: "admin.expense.travel.reports",
              route: "/travel/reports",
              active: false,
            },
          },
        },
        accounting: {
          name: "admin.expense.accounting.title",
          icon: "IconCalculator",
          route: "",
          active: false,
          subMenus: {
            accountability: {
              name: "admin.expense.accounting.accountability",
              route: "/expenseManagement/accounting/pending",
              active: false,
            },
            companyPayables: {
              name: "admin.expense.accounting.companyPayables",
              route: "/expense/company-payables/accounting",
              active: false,
            },
            advances: {
              name: "admin.expense.accounting.advances",
              icon: "IconCurrencyDollar",
              route: "/expenseManagement/accounting/advances",
              active: false,
            },
          },
        },
        analytics: {
          name: "admin.expense.analytics",
          icon: "IconPresentation",
          route: "/expenseManagement/analytics",
          active: false,
        },
        customizations: {
          name: "admin.expense.customizations",
          icon: "IconAdjustmentsHorizontal",
          route: "/expenseManagement/customizations",
          active: false,
        },
      },
    },
    people: {
      active: false,
      name: "admin.people.title",
      menus: {
        recruitment: {
          name: "admin.people.recruitment.title",
          icon: "IconUserPlus",
          active: false,
          route: "",
          isNew: true,
          subMenus: {
            jobOpenings: {
              name: "admin.people.recruitment.jobOpenings",
              route: "/recruitment/job-openings",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_recruitment_jobOpenings",
                  businessUnit: "recruitment",
                }),
            },
          },
        },
        employmentLifecycle: {
          active: false,
          name: "admin.people.employmentLifecycle.title",
          icon: "IconLayoutKanban",
          route: "/",
          subMenus: {
            hiring: {
              name: "admin.people.employmentLifecycle.hiring",
              route: "/flows/hiring",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_employmentLifecycle_hiring",
                  businessUnit: "people",
                }),
            },
            termination: {
              name: "admin.people.employmentLifecycle.termination",
              route: "/flows/resignation",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_employmentLifecycle_termination",
                  businessUnit: "people",
                }),
            },
            recognition: {
              name: "admin.people.employmentLifecycle.recognition",
              route: "/recognition",
              isNew: true,
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_employmentLifecycle_recognition",
                  businessUnit: "people",
                }),
            },
          },
        },
        timeAndAttendance: {
          active: false,
          name: "admin.people.working.title",
          icon: "IconClock",
          route: "/",
          subMenus: {
            approvals: {
              name: "admin.people.working.approvals",
              route: "/time-and-attendance/approvals",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_approvals",
                  businessUnit: "people",
                }),
            },
            activities: {
              name: "admin.people.working.activities",
              route: "/time-and-attendance/activities",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_activities",
                  businessUnit: "people",
                }),
            },
            absences: {
              name: "admin.people.working.absences",
              route: "/time-and-attendance/events",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_absences",
                  businessUnit: "people",
                }),
            },
            manageWorkshifts: {
              name: "admin.people.working.manageWorkshifts",
              route: "/time-and-attendance/manage-workshift",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_manageWorkshifts",
                  businessUnit: "people",
                }),
            },
            vacations: {
              name: "admin.people.working.vacations",
              route: "/time-and-attendance/manage-vacations",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_vacations",
                  businessUnit: "people",
                }),
            },
            timeSheets: {
              name: "admin.people.working.timeSheet",
              route: "/time-and-attendance/time-sheets",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_timeSheets",
                  businessUnit: "people",
                }),
            },
            integrations: {
              name: "admin.people.working.integrations",
              route: "/time-and-attendance/integrations",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_integrations",
                  businessUnit: "people",
                }),
            },
            dataExport: {
              name: "admin.people.working.dataExport",
              route: "/time-and-attendance/integrations/export-data",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_dataExport",
                  businessUnit: "people",
                }),
            },
            imports: {
              name: "admin.people.working.imports",
              route: "/time-and-attendance/integrations/imports",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_imports",
                  businessUnit: "people",
                }),
            },
            timeBank: {
              name: "admin.people.working.timeBank",
              route: "/time-and-attendance/bank-hours",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_timeBank",
                  businessUnit: "people",
                }),
            },
            workScheduleRules: {
              name: "admin.people.working.workScheduleRules",
              route: "/time-and-attendance/preferences/rule-calcs",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_timeAndAttendance_workScheduleRules",
                  businessUnit: "people",
                }),
            },
          },
        },
        performance: {
          name: "admin.people.performance.title",
          icon: "IconRocket",
          route: "",
          active: false,
          subMenus: {
            cycles: {
              name: "admin.people.performance.cycles",
              route: "/performance/manage-cycles",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_performance_cycles",
                  businessUnit: "people",
                }),
            },
            criterials: {
              name: "admin.people.performance.criterials",
              route: "/performance/criterials",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_performance_criterials",
                  businessUnit: "people",
                }),
            },
            evaluations: {
              name: "admin.people.performance.evaluations",
              route: "/performance/evaluations/v2",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_performance_evaluations",
                  businessUnit: "people",
                }),
            },
            feedbacks: {
              name: "admin.people.performance.feedbacks",
              route: "/performance/manage-feedbacks",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_performance_feedbacks",
                  businessUnit: "people",
                }),
            },
            idp: {
              name: "admin.people.performance.idp",
              route: "/performance/manage-idps",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_performance_idp",
                  businessUnit: "people",
                }),
            },
          },
        },
        lms: {
          name: "admin.people.training",
          icon: "IconSchool",
          route: "/lms/manage-courses",
          active: false,
          onClick: () =>
            track({
              type: "menu_clicked",
              screen: "admin_lms",
              businessUnit: "people",
            }),
        },
        engagement: {
          name: "admin.people.engagement.title",
          icon: "IconSpeakerphone",
          route: "/",
          active: false,
          subMenus: {
            communications: {
              name: "admin.people.engagement.communications",
              route: "/engagement/communication",
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_engagement_communications",
                  businessUnit: "people",
                }),
            },
            surveys: {
              name: "admin.people.engagement.surveys",
              route: "/engagement/survey",
              isNew: true,
              active: false,
              onClick: () =>
                track({
                  type: "menu_clicked",
                  screen: "admin_engagement_surveys",
                  businessUnit: "people",
                }),
            },
          },
        },
        orgchart: {
          name: "admin.people.orgchart",
          icon: "IconHierarchy",
          route: "/orgchart",
          active: false,
          onClick: () =>
            track({
              type: "menu_clicked",
              screen: "admin_orgchart",
              businessUnit: "people",
            }),
        },
        insights: {
          name: "admin.people.insights",
          icon: "IconChartPie",
          route: "/analytics",
          active: false,
          onClick: () =>
            track({
              type: "menu_clicked",
              screen: "admin_insights",
              businessUnit: "people",
            }),
        },
      },
    },
    settings: {
      id: "settings",
      name: "admin.settings",
      route: "/settings",
      icon: "IconSettings",
      active: false,
    },
  },
  newProducts: [
    {
      name: "Gestão de despesas",
      to: "/expenseManagement/subscription",
      menuName: "admin.expense.title",
      active: false,
    },
  ],
});

export type AdminMenuType = ReturnType<typeof getAdminMenu>;
