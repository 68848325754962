import { IToggle } from "unleash-proxy-client";
import { checkUnleashToggle } from "../../utils/checkUnleashPermission";
import {
  ECorporateCardNotificationsKey,
  EExpenseContractType,
  EExpenseMenuIcon,
  EExpenseMenuItem,
  EExpenseNotificationsKey,
  EExpensePermissions,
  IExpensesContracts,
  MenuGroupsProps,
} from "./types";

export function getExpensesMenuGroup(
  isAdmin: boolean,
  hasSettings: boolean,
  permissions: string[],
  expensesContractsData?: IExpensesContracts,
  unleashFlags?: IToggle[]
): MenuGroupsProps {
  const hasCorporateCardContract = checkSignatureContracts(
    EExpenseContractType.FLASH_APP_CONTRACT_CORPORATE_CARD
  );
  const hasExpensesContract = checkSignatureContracts(
    EExpenseContractType.FLASH_APP_SAAS_SERVICE_CONTRACT
  );
  const expensesMenuGroups: MenuGroupsProps = {
    name: "expenses.title",
    id: "flash_expenses",
    menus: [],
  };

  if (hasCorporateCardContract && expensesContractsData.isDisabledExpense) {
    const allowedCardSetting =
      isAdmin || permissions?.includes("expense_manage_corporate_cards");

    appendCorporateCardMenus(expensesMenuGroups);

    isAdmin &&
      expensesMenuGroups.menus.push({
        name: `expenses.${EExpenseMenuItem.analytics}`,
        route: `/expenseManagement/${EExpenseMenuItem.analytics}`,
        icon: EExpenseMenuIcon[EExpenseMenuItem.analytics],
        permissions: getPermissions(EExpenseMenuItem.analytics),
        isLoading: expensesContractsData?.isLoading,
      });

    allowedCardSetting &&
      expensesMenuGroups.menus.push({
        name: `expenses.${EExpenseMenuItem.customizations}`,
        route: `/expenseManagement/${EExpenseMenuItem.customizations}`,
        icon: EExpenseMenuIcon[EExpenseMenuItem.customizations],
        permissions: getPermissions(EExpenseMenuItem.customizations),
        isLoading: expensesContractsData?.isLoading,
      });

    return expensesMenuGroups;
  }

  const hasSignPermission =
    permissions.includes(EExpensePermissions.contractSign) ||
    permissions.includes("*");

  function getPermissions(
    menuItem: EExpenseMenuItem
  ): EExpensePermissions[] | null {
    const defaultPermissions = [
      EExpensePermissions.expenses,
      EExpensePermissions.analytics,
    ];
    const isDefaultPermissions = defaultPermissions.some(
      (permission) => EExpensePermissions[menuItem] === permission
    );

    return !isDefaultPermissions ? [EExpensePermissions[menuItem]] : null;
  }

  function checkLicenseFeature(licenseFeature: string): boolean {
    const features: string[] = expensesContractsData?.features || [];
    return features?.some((_feature) => _feature === licenseFeature);
  }

  function checkSignatureContracts(contractType: EExpenseContractType) {
    const contracts: EExpenseContractType[] =
      expensesContractsData?.contracts || [];

    return contracts?.some(
      (_contract) => _contract === contractType || _contract === contractType
    );
  }

  function checkShowMenuItem(_menuItem: EExpenseMenuItem) {
    return (
      checkPermissions(_menuItem) &&
      (hasExpensesContract || isAdmin || hasSettings || hasSignPermission)
    );
  }

  function checkPermissions(menuItem: EExpenseMenuItem): boolean {
    const canSignContract = isAdmin || hasSettings || hasSignPermission;

    const isExpenseTabShown =
      checkLicenseFeature("BASIC_EXPENSE") || canSignContract;

    switch (menuItem) {
      case EExpenseMenuItem.corporateCard:
        return hasCorporateCardContract || canSignContract;
      case EExpenseMenuItem.expenses:
      case EExpenseMenuItem.accounting:
      case EExpenseMenuItem.customizations:
      case EExpenseMenuItem.travel:
        return isExpenseTabShown;
      case EExpenseMenuItem.advances:
        return checkLicenseFeature("INTERMEDIATE_ADVANCE");
      case EExpenseMenuItem.approvals:
        return checkLicenseFeature("INTERMEDIATE_APPROVALS");
      case EExpenseMenuItem.analytics:
        return checkLicenseFeature("BASIC_ANALYTICS");
    }
  }

  function getExpenseRoute(route: string) {
    if (hasCorporateCardContract) {
      return route;
    }

    return "/expenseManagement/subscription";
  }

  function appendCorporateCardMenus(menuGroups: MenuGroupsProps) {
    menuGroups.menus.push({
      name: "expenses.corporateCard.title",
      icon: EExpenseMenuIcon.corporateCard,
      permissions: [
        "expense_manage_corporate_cards",
        "expense_manage_corporate_card_balances",
      ],
      isLoading: expensesContractsData?.isLoading,
      notificationServiceName: "corporateCardBff",
      notificationRoute: "sideMenu.notifications",
      subMenus: [
        {
          name: "expenses.corporateCard.home",
          route: getExpenseRoute("/corporateCard/home"),
          permissions: ["expense_manage_corporate_cards"],
        },
        {
          name: "expenses.corporateCard.management",
          route: getExpenseRoute("/corporateCard/management"),
          permissions: ["expense_manage_corporate_card_balances"],
          notificationKey: ECorporateCardNotificationsKey.manageBalance,
        },
      ],
    });

    if (
      checkLicenseFeature("BASIC_CARDS") &&
      checkUnleashToggle(
        "FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS",
        unleashFlags
      ) &&
      checkUnleashToggle(
        "FLASH_OS_CORPORATE_CARD_EXTERNAL_CARDS_V2",
        unleashFlags
      )
    ) {
      menuGroups.menus.push({
        name: "admin.expense.externalCards",
        icon: EExpenseMenuIcon.corporateCard,
        permissions: [
          "expense_manage_corporate_cards",
          "expense_manage_corporate_card_balances",
        ],
        isLoading: expensesContractsData?.isLoading,
        route: getExpenseRoute("/corporateCard/externalCards"),
      });
    }
  }

  function appendTravelMenus(menuGroups: MenuGroupsProps) {
    menuGroups.menus.push({
      name: "expenses.travel.title",
      icon: EExpenseMenuIcon.travel,
      subMenus: [
        {
          name: "expenses.travel.list",
          route: "/travel/list",
          permissions: ["expense_travel_view_list"],
        },
        {
          name: "expenses.travel.search",
          route: "/travel/sale",
          permissions: ["expense_travel_view_sale"],
        },
        {
          name: "expenses.travel.approvals",
          route: "/travel/approvals",
          permissions: ["expense_travel_view_approvals"],
        },
        {
          name: "expenses.travel.monitoring",
          route: "/travel/monitoring",
          permissions: ["expense_travel_view_monitoring"],
        },
        {
          name: "expenses.travel.reports",
          route: "/travel/reports",
          permissions: ["expense_travel_view_reports"],
        },
      ],
      route: null,
      isLoading: expensesContractsData?.isLoading,
    });
  }

  function appendVendorMenu(menuGroups: MenuGroupsProps) {
    const vendorMenu = {
      name: "expenses.vendors",
      icon: EExpenseMenuIcon.vendor,
      route: "expense/company-payables/vendors",
      permissions: ["expense_vendor_view"],
      isNew: true,
    };

    menuGroups.menus.push(vendorMenu);
  }

  function appendAccountingSubMenus() {
    const accountingSubMenus = [
      {
        name: "expenses.accounting.accountability",
        route: "/expenseManagement/accounting/pending",
        permissions: ["expense_view_financial_reports"],
        notificationKey: EExpenseNotificationsKey.accountabilityAccount,
      },
    ];

    if (
      checkUnleashToggle("FLASH_OS_COMPANY_PAYABLES", unleashFlags) &&
      hasCorporateCardContract
    ) {
      accountingSubMenus.push({
        name: "expenses.accounting.payables",
        route: "/expense/company-payables/accounting",
        permissions: ["expense_payables_account_view"],
        notificationKey: undefined,
      });
    }

    if (checkLicenseFeature("ADVANCED_ADVANCE")) {
      accountingSubMenus.push({
        name: "expenses.accounting.advances",
        route: "/expenseManagement/accounting/advances",
        permissions: ["expense_view_approvals_advances"],
        notificationKey: EExpenseNotificationsKey.advancesAccount,
      });
    }

    return accountingSubMenus;
  }

  if (
    checkUnleashToggle("FLASH_OS_EXPENSE_MANAGEMENT", unleashFlags) ||
    isAdmin ||
    hasSettings
  )
    Object.values(EExpenseMenuItem).forEach((_menuItem) => {
      const isCorporateCard: boolean =
        _menuItem === EExpenseMenuItem.corporateCard;
      const isTravel: boolean = _menuItem === EExpenseMenuItem.travel;
      const isVendor: boolean = _menuItem === EExpenseMenuItem.vendor;

      if (isCorporateCard && checkPermissions(EExpenseMenuItem.corporateCard)) {
        appendCorporateCardMenus(expensesMenuGroups);
        // Desabilitar temporariamente os menus de viagens
        // } else if (isTravel && checkPermissions(EExpenseMenuItem.travel)) {
        //   appendTravelMenus(expensesMenuGroups);
      } else if (
        checkUnleashToggle("FLASH_OS_COMPANY_PAYABLES", unleashFlags) &&
        isVendor &&
        hasExpensesContract
      ) {
        appendVendorMenu(expensesMenuGroups);
      } else {
        checkShowMenuItem(_menuItem) &&
          expensesMenuGroups.menus.push({
            name: `expenses.${_menuItem}${
              _menuItem === EExpenseMenuItem.accounting ? ".title" : ""
            }`,
            route: `/expenseManagement/${_menuItem}`,
            icon: EExpenseMenuIcon[_menuItem],
            permissions: getPermissions(_menuItem),
            isLoading: expensesContractsData?.isLoading,
            subMenus:
              _menuItem === EExpenseMenuItem.accounting
                ? appendAccountingSubMenus()
                : undefined,
          });
      }
    });

  return expensesMenuGroups;
}
