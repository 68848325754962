import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Typography, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

const StyledListItem = styled.li<{
  isActive?: boolean;
  open?: boolean;
  isExpandable?: boolean;
  marginTop?: boolean;
}>`
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid transparent;
  ${({ theme, isActive, open }) =>
    isActive &&
    !open &&
    `background-color: ${theme.colors.secondary[99]}; border: 1px solid ${theme.colors.secondary[95]}
    `};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs4}`};
  align-items: center;
  position: relative;
  margin: ${({ theme }) => `0 ${theme.spacings.xs4}`};
  ${({ theme, marginTop }) =>
    marginTop &&
    `margin-top: ${theme.spacings.xs3}
    `};
`;

const FlexColumn = styled.div<{ sideBarOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ sideBarOpen }) => (sideBarOpen ? "100%" : "auto")};
`;

const ItemSkeleton = styled(Skeleton)`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 10%;
  margin-bottom: -3%;
`;

const NotificationContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
`;

const CommonContainer = css<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: ${({ $sideBarOpen }) =>
    $sideBarOpen ? "flex-start" : "center"};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  padding: ${({ theme, $hasNotification, $sideBarOpen }) =>
    `${
      $hasNotification && !$sideBarOpen
        ? theme.spacings.xs5
        : theme.spacings.xs4
    } 0`};

  ${({ $active }) =>
    $active &&
    css`
      p {
        color: ${({ theme }) => theme.colors.secondary.dark.dark5};
      }

      svg {
        stroke: ${({ theme }) => theme.colors.secondary.dark.dark5} !important;
      }
    `}

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.secondary.dark.dark5};
    }
  }
`;

const StyledLink = styled(NavLink)<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  ${CommonContainer};
`;

const StyledRedirectLink = styled.a<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  ${CommonContainer};
`;

const MenuContainer = styled.div<{
  $active: boolean;
  $sideBarOpen: boolean;
  $hasNotification?: boolean;
}>`
  border-radius: ${({ theme }) => theme.borders.radius.xs};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  padding: ${({ theme }) => `${theme.spacings.xs4} 0`};
`;

const IconContainer = styled.div<{
  open: boolean;
}>`
  display: flex;
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease, opacity 0.3s linear;
`;

const StyledIcon = styled(Icons)<{
  primary: boolean;
  sideBarOpen?: boolean;
  hasNotification?: boolean;
  invisible?: boolean;
}>`
  color: ${({ theme }) => theme.colors.neutral[70]};
  ${({ invisible }) => invisible && "opacity: 0"};
`;

const Title = styled(Typography)<{ $sideBarOpen: boolean }>`
  display: block;
  display: -webkit-box;
  transition: opacity 0.3s ease;
  overflow: hidden;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.neutral[30]};
  && {
    margin: ${({ theme }) => `0 0 0 ${theme.spacings.xs3}`};
    font-weight: 600;
    line-height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const NotificationText = styled(Typography)`
  display: block;
  display: -webkit-box;
  transition: opacity 0.3s ease;
  overflow: hidden;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.secondary[50]};
  font-weight: 700;
`;

const SubMenus = styled.ul<{ $active: boolean }>`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: all 0.5s ease;
  border-left: ${({ theme }) => `2px solid ${theme.colors.neutral[95]}`};
  margin-left: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => `0 ${theme.spacings.xs4}`};
`;

const FlexCenter = styled.div<{
  $sideBarOpen: boolean;
  $spaceBetween?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $spaceBetween }) => $spaceBetween && "space-between"};
  gap: ${({ theme, $spaceBetween }) => !$spaceBetween && theme.spacings.xs};
  flex: 1;
  opacity: ${({ $sideBarOpen }) => ($sideBarOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in;

  ${({ $sideBarOpen }) =>
    !$sideBarOpen &&
    `
     width: 0;
     height: 0;
     flex: 0;
    `};
`;

const NewTag = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs3}`};
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

const NewTagText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};

  && {
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`;

const NewItemIcon = styled(Icons)<{ $sideBarOpen: boolean }>`
  position: absolute;
  margin-top: ${({ theme }) => theme.spacings.s};
  margin-left: ${({ theme }) => theme.spacings.xs4};
  border-radius: ${({ theme }) => theme.borders.radius.xs};
  background-color: #ffffff;
  border: 1px solid #ffffff;
  opacity: ${({ $sideBarOpen }) => ($sideBarOpen ? 0 : 1)};
  transition: opacity 0.3s ease-in;

  svg {
    stroke: rgb(242, 13, 122);
  }
`;

const NewTagFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs4}`};
  margin-right: ${({ theme }) => theme.spacings.xs4};
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

const NewTagItemIcon = styled(Icons)`
  position: relative;
  margin-left: ${({ theme }) => theme.spacings.xs5};
  color: rgb(242, 13, 122);

  svg {
    color: rgb(242, 13, 122);
    stroke: rgb(242, 13, 122);
    fill: transparent;
  }
`;

export {
  StyledListItem,
  StyledIcon,
  StyledLink,
  StyledRedirectLink,
  MenuContainer,
  Title,
  IconContainer,
  SubMenus,
  FlexColumn,
  FlexCenter,
  NewTag,
  NewTagText,
  NewItemIcon,
  ItemSkeleton,
  NotificationContainer,
  NotificationText,
  NewTagFlex,
  NewTagItemIcon,
};
