import {
  checkActiveModule,
  fetchActiveModules,
} from "@flash-tecnologia/expense-web-activation-utils";
import { CompanyWithPermissions } from "@flash-tecnologia/hros-web-utility/dist/custom-hooks/types";
import { mutate } from "swr";
import { AdminMenuType } from "../Admin/adminMenu";
import { EmployeeMenuType } from "../Employee/employeeMenu";

function hasPermission(
  companyPermissions: CompanyWithPermissions,
  requiredPermissions: string[]
) {
  if (companyPermissions.permissions.indexOf("*") !== -1) {
    return true;
  }

  return requiredPermissions.every(
    (perm) => companyPermissions.permissions.indexOf(perm) !== -1
  );
}

export function setUpEmployeeMenuForExpenseLifecycle(
  employeeMenuButtons: EmployeeMenuType["menuItems"],
  companyPermissions: CompanyWithPermissions
) {
  // disabling legacy as a failsafe
  employeeMenuButtons.expenseCorp.active = false;
  employeeMenuButtons.expenseCorp.subMenus.advance.active = false;
  employeeMenuButtons.expenseCorp.subMenus.analytics.active = false;
  employeeMenuButtons.expenseCorp.subMenus.accountability.active = false;

  employeeMenuButtons.expenseLifecycle.subMenus.reimbursements.active = true;
  employeeMenuButtons.expenseLifecycle.subMenus.statement.active = true;
}

export function setUpAdminMenuForExpenseLifecycle(
  adminMenuButtons: AdminMenuType["menuItems"],
  companyPermissions: CompanyWithPermissions
) {
  // disabling legacy as a failsafe
  adminMenuButtons.expense.menus.externalCards.active = false;
  adminMenuButtons.expense.menus.suppliers.active = false;
  adminMenuButtons.expense.menus.accounting.active = false;
  adminMenuButtons.expense.menus.analytics.active = hasPermission(
    companyPermissions,
    ["expense_view_financial_reports", "expense_refund_report"]
  );

  const submenus = adminMenuButtons.expense.menus.accounting.subMenus;
  Object.keys(submenus).forEach((key) => (submenus[key].active = false));

  adminMenuButtons.expense.menus.reimbursementsClosing.active = hasPermission(
    companyPermissions,
    ["expense_view_financial_reports", "expense_refund_report"]
  );
  adminMenuButtons.expense.menus.corporateCard.subMenus.closing.active =
    hasPermission(companyPermissions, [
      "expense_view_financial_reports",
      "expense_refund_report",
    ]);

  adminMenuButtons.expense.menus.dashboard.active = hasPermission(
    companyPermissions,
    ["expense_view_financial_reports", "expense_refund_report"]
  );

  const hasApprovalPermissions = hasPermission(companyPermissions, [
    "expense_view_reports_approval",
    "expense_approve_report",
  ]);
  if (
    hasApprovalPermissions &&
    checkActiveModule("approval", companyPermissions.id)
  ) {
    adminMenuButtons.expense.menus.approvals.active = true;
    adminMenuButtons.expense.menus.approvals.route =
      "/expense/approvals/pending-approvals";
  }
}

export function refetchExpenseMenus(companyId: string) {
  if (!companyId) return;

  mutate(`expenses-company-data-${companyId}`);
  fetchActiveModules(companyId);
}
