import type { UsuarioFolhaCerta } from "../../api";
import type { SubMenuProps } from "./types";

type FolhaCertaPermissions = Array<
  keyof ReturnType<typeof flattenFCUserPermissions>
>;

type SubMenuItem = SubMenuProps &
  (
    | {
        folhaCertaPermissions: FolhaCertaPermissions;
        permissions?: never;
      }
    | {
        folhaCertaPermissions?: never;
        permissions: string[];
      }
  );

const subMenuItems: SubMenuItem[] = [
  {
    name: "people.timeAndAttendance.clockin",
    route: "time-and-attendance/clockin",
    folhaCertaPermissions: ["ExibirMarcarPontoWeb"],
  },
  {
    name: "people.timeAndAttendance.timeSheet",
    route: "time-and-attendance/time-sheet",
    folhaCertaPermissions: ["MarcarPonto"],
  },
  {
    name: "people.timeAndAttendance.workshift",
    route: "time-and-attendance/workshift",
    permissions: ["people_time_and_attendance_view_workshift"],
  },
  {
    name: "people.timeAndAttendance.myVacations",
    route: "time-and-attendance/vacations",
    folhaCertaPermissions: ["ExibirFerias"],
  },
  {
    name: "people.timeAndAttendance.approvals",
    route: "time-and-attendance/approvals",
    folhaCertaPermissions: ["IsAdmin", "IsGestor"],
  },
  {
    name: "people.timeAndAttendance.activities",
    route: "time-and-attendance/activities",
    folhaCertaPermissions: ["IsAdmin", "IsGestor"],
  },
  {
    name: "people.timeAndAttendance.events",
    route: "time-and-attendance/events",
    permissions: ["people_time_and_attendance_manage_events"],
  },
  {
    name: "people.timeAndAttendance.manageWorkshifts",
    route: "time-and-attendance/manage-workshifts",
    permissions: ["people_time_and_attendance_manage_workshifts"],
  },
  {
    name: "people.timeAndAttendance.vacations",
    route: "time-and-attendance/manage-vacations",
    permissions: ["people_time_and_attendance_manage_vacations"],
  },
  {
    name: "people.timeAndAttendance.timeSheets",
    route: "time-and-attendance/time-sheets",
    permissions: ["people_time_and_attendance_manage_timesheets"],
  },
  {
    name: "people.timeAndAttendance.integrations",
    route: "time-and-attendance/integrations",
    folhaCertaPermissions: ["IsAdmin"],
  },
  {
    name: "people.timeAndAttendance.exportData",
    route: "time-and-attendance/export-data",
    folhaCertaPermissions: ["IsAdmin", "IsGestor"],
  },
  {
    name: "people.timeAndAttendance.imports",
    route: "time-and-attendance/imports",
    folhaCertaPermissions: ["IsAdmin"],
  },
  {
    name: "people.timeAndAttendance.bankHours",
    route: "time-and-attendance/bank-hours",
    folhaCertaPermissions: ["IsAdmin"],
  },
  {
    name: "people.timeAndAttendance.preferences",
    route: "time-and-attendance/preferences",
    permissions: ["people_time_and_attendance_manage_settings"],
  },
];

function flattenFCUserPermissions(fcUser: UsuarioFolhaCerta) {
  const { IsAdmin, IsGestor, Configuracao } = fcUser;
  const { RegraInterjornada, RegraIntrajornada, UsuariosGestores, ...rest } =
    Configuracao;

  return {
    IsAdmin,
    IsGestor,
    PermitirRegraInterjornada: !!RegraInterjornada?.Habilitar,
    PermitirRegraInterjornadaKiosk: !!RegraInterjornada?.HabilitarKiosk,
    PermitirRegraIntrajornada: !!RegraIntrajornada?.Habilitar,
    PermitirRegraIntrajornadaKiosk: !!RegraIntrajornada?.HabilitarKiosk,
    ...rest,
  };
}

function getAllowedFCSubMenu(fcUser?: UsuarioFolhaCerta): SubMenuProps[] {
  if (!fcUser) {
    return subMenuItems.filter((menuItem) => !!menuItem.permissions);
  }

  const fcUserPermissions = Object.entries(
    flattenFCUserPermissions(fcUser)
  ).reduce((acc, [key, value]) => {
    if (value) acc.push(key);
    return acc;
  }, []) as FolhaCertaPermissions;

  return subMenuItems.reduce((acc, menuItem) => {
    if (
      !menuItem.folhaCertaPermissions ||
      !menuItem.folhaCertaPermissions.length
    ) {
      acc.push(menuItem);
      return acc;
    }

    const userHasPermission = menuItem.folhaCertaPermissions.some((p) =>
      fcUserPermissions.includes(p)
    );

    if (userHasPermission) {
      const { folhaCertaPermissions, ...restMenuItem } = menuItem;
      acc.push(restMenuItem);
    }

    return acc;
  }, []);
}

export function getTimeAndAttendanceMenu(folhaCertaUser?: UsuarioFolhaCerta) {
  const subMenu = getAllowedFCSubMenu(folhaCertaUser);

  return {
    name: "people.timeAndAttendance.timeAndAttendance",
    icon: "IconClock",
    isNew: true,
    subMenus: subMenu,
  };
}
